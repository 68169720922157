<template>
    <div>
        <FInput
            class="input-url flex flex-wrap w-full mt-5 px-2"
            :value="outputURL"
            :label="$t(`common.modals.editLinkModal.labels.url`)"
            title="URL"
            can-copy
            :can-copy-title="$t('common.components.copyButton.label')"
            can-copy-class="text-purple-800 hover:text-purple-800 hover:opacity-80 focus:text-purple-800"
            disabled>
        </FInput>

        <div class="w-full flex flex-wrap">
            <div
                :key="key"
                v-for="(value, key) in fields"
                class="w-full laptop:w-1/2 f-col">
                <span class="text-xs">{{ translatedLabel(key) }}</span>
                <FSelect
                    v-if="key === 'globalSource'"
                    :get-data-vuex="getGlobalSource"
                    prop="value"
                    :placeholder="translatedLabel(key)"
                    :value="value"
                    @input="updateField(key, $event)">
                    <template #text="{ option }">
                        <NameTranslationOutput
                            :name="option.value"
                            namespace="common.entity.globalSource"></NameTranslationOutput>
                    </template>
                    <template #selected-option="{ option }">
                        <NameTranslationOutput
                            :name="option.value"
                            namespace="common.entity.globalSource"></NameTranslationOutput>
                    </template>
                </FSelect>
                <FInput
                    v-else
                    class="w-full"
                    :placeholder="translatedLabel(key)"
                    :value="value"
                    @input="updateField(key, $event)">
                </FInput>
            </div>
        </div>
    </div>
</template>

<script>
  import FInput from "@/components/Common/FInput";
  import FSelect from "@/components/Common/FSelect";
  import { mapActions, mapState } from "vuex";
  import { GET_GLOBAL_SOURCE } from "@core/store/action-constants";
  import NameTranslationOutput from "@/components/Common/NameTranslationOutput";

  export default {
    name: "WebmasterEditLinkTemplate",
    components: {
      FSelect,
      FInput,
      NameTranslationOutput
    },
      
    props: {
      namespace: {
        type: String,
        default: "common.modals.editLinkModal.labels"
      },
        
      keyNoLink: {
        type: String,
        default: "webmaster.flows.table.values.noLink"
      },
        
      url: {
        type: String,
        default: null
      },
        
      fields: {
        type: Object,
        default: null
      }
    },
      
    computed: {
      ...mapState("webmaster/flows/flowsEditLinkModal", {
        globalSource: ({ globalSourceFilters }) => globalSourceFilters.data
      }),
      
      outputURL () {
        return this.url ?? this.$t(this.keyNoLink);
      }
    },
      
    methods: {
      ...mapActions("webmaster/flows/flowsEditLinkModal", {
        GET_GLOBAL_SOURCE: `globalSourceFilters/${ GET_GLOBAL_SOURCE }`
      }),
      
      translatedLabel (key) {
        const translationKey = `${ this.namespace }.${ key }`;

        return this.$te(translationKey) ? this.$t(translationKey) : key;
      },

      updateField (key, payload) {
        payload = payload === "" ? null : payload;
            
        this.$emit("update:flowFields", { [key]: payload });
      },
      async getGlobalSource () {
        await this.GET_GLOBAL_SOURCE();
        return this.globalSource;
      }
    }
  };
</script>

<style scoped>

</style>