<template>
    <div>
        <DetailsFilters
            :filters.sync="filters"
            :is-loading="isLoading"
            @clear="clearFilters"
            @update="update">
        </DetailsFilters>
        <DetailsTable
            :data="statistic"
            :is-loading="isLoading"
            :currency="currency_">
        </DetailsTable>
    </div>
</template>

<script>
  import { momentInst } from "@core/filters";
  import { FilteredComponent } from "@core/mixins";
  import { GET_STATISTIC } from "@core/store/action-constants";
  import { mapActions, mapState } from "vuex";
  import DetailsFilters from "@/components/Admin/Users/WebmastersTab/WebmastersTableDetailsModal/WebmastersTableDetailsModalDetails/DetailsFilters";
  import DetailsTable from "@/components/Admin/Users/WebmastersTab/WebmastersTableDetailsModal/WebmastersTableDetailsModalDetails/DetailsTable";
  
  export default {
    name: "WebmastersTableDetailsModalDetails",
    mixins: [FilteredComponent],
    components: {
      DetailsTable,
      DetailsFilters
    },
    props: {
      webmaster: {
        type: Object,
        default: null
      },
      isActive: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        filters: {
          datepicker: {
            dateStart: momentInst().startOf("day").subtract(7, "day").toDate(),
            dateEnd: momentInst().endOf("day").toDate()
          },
          groups: [null, "offer", "advertiser"],
          currency: this.$store.state.auth.preferredCurrency,
          advertiserId: null,
          offerId: null
        },
        currency_: this.$store.state.auth.preferredCurrency
      };
    },
    computed: {
      ...mapState({
        statistic: state => state.admin.statistic.statistics
      }),

      isLoading () {
        return this.$wait(`admin/${ GET_STATISTIC }`);
      }
    },
    methods: {
      ...mapActions("admin", [
        GET_STATISTIC
      ]),

      async update () {
        if (this.isActive) {
          const { datepicker, groups, ...filters } = this.filters;
          const queryGroups = groups.map(item => item?.toUpperCase()).filter(Boolean);

          await this.GET_STATISTIC({
            groups: queryGroups,
            filters: { ...datepicker, ...filters, webmasterId: this.webmaster?.id }
          });
          this.currency_ = filters.currency;
        }
      }
    },
    watch: {
      isActive (value) {
        if (value) this.update();
      }
    }
  };
</script>

<style scoped>

</style>
