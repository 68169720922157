<template>
    <div
        class="countries">
        <div
            :key="index"
            v-for="(country, index) in countries"
            class="countries_item">
            <flag :iso="country.alpha2"></flag>
            {{ country.name }}
        </div>
    </div>
</template>

<script>
  export default {
    name: "CountriesList",
    props: {
      countries: {
        required: true,
        default: () => [],
        type: Array
      }
    }
  };
</script>

<style scoped lang="scss">
    .countries {
        @apply w-full flex flex-wrap overflow-y-auto;
        .countries_item {
            @apply w-full bg-white mt-2 bg-white overflow-hidden f-col shadow-md;
        }
    }
</style>