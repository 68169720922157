<template>
    <Offer
        :back-link="backLink"
        :offer="offer"
        :banned-traffic-types="trafficTypes">
        <template #content>
            <div
                v-show="hasWebmasters"
                class="column is-12">
                <BlockBox
                    :title="$t('admin.offer.table.offerWebmasters.labels.header')">
                    <OfferWebmastersTable :offer="offerLikeGql"></OfferWebmastersTable>
                </BlockBox>
            </div>
            <div
                v-show="hasCallCenters"
                class="column is-12">
                <CallCentersTable
                    :call-centers="callCenters"
                    :is-loading="isLoading.callcentres"
                    @changeTab="getCallCenters($event)">
                </CallCentersTable>
            </div>
            <div class="column is-12">
                <div class="columns">
                    <div class="column is-6">
                        <OfferLandings
                            date
                            :data="transits"
                            :is-loading="isLoading.transits"
                            :label-language="$t('common.entity.transits.table.labels.language')"
                            :label-name="$t('common.entity.transits.table.labels.name')"
                            :pagination.sync="transitsPagination"
                            :title="$t('common.entity.transits.title')"
                            @update="GET_OFFER_TRANSITS">
                            <template #empty>
                                {{ $t("common.entity.transits.table.empty") }}
                            </template>
                        </OfferLandings>
                    </div>
                    <div class="column is-6">
                        <OfferLandings
                            date
                            :data="landings"
                            :is-loading="isLoading.landings"
                            :label-language="$t('common.entity.landings.table.labels.language')"
                            :label-name="$t('common.entity.landings.table.labels.name')"
                            :pagination.sync="landingsPagination"
                            :title="$t('common.entity.landings.title')"
                            @update="GET_OFFER_LANDINGS">
                            <template #empty>
                                {{ $t("common.entity.landings.table.empty") }}
                            </template>
                        </OfferLandings>
                    </div>
                </div>
            </div>
        </template>
        <template #aside>
            <Permissions :permissions="['OFFERS.EDIT']">
                <div
                    v-loading="!offer"
                    class="column is-12">
                    <BlockBox title="">
                        <router-link
                            v-if="offer"
                            :to="{ name: 'admin:offers:edit', query: { offerId: offer.id } }">
                            <b-button
                                expanded
                                type="is-warning">
                                {{ $t(`admin.offer.details.aside.buttons.edit`) }}
                            </b-button>
                        </router-link>
                    </BlockBox>
                </div>
            </Permissions>
            <OfferAsideStyleComponent
                v-slot="{ webmasterClass }"
                :has-promo="offerAsideStyleComponentOptions.hasPromo"
                :traffic-amount="offerAsideStyleComponentOptions.trafficAmount">
                <OfferWebmasters
                    class="column is-12-fullhd m-0"
                    :class="webmasterClass"
                    :offer="offer"
                    :statistics="statistics">
                </OfferWebmasters>
            </OfferAsideStyleComponent>

            <FCard
                v-if="offer"
                class="mt-4">
                <template #header>
                    <span class="f-col text-xl font-bold">
                        {{ $t('common.entity.offer.geo') }}
                        <span class="text-gray-400"> {{ countries.length }}</span>
                    </span>
                </template>
                <template>
                    <CountriesList
                        :countries="countries"
                        style="max-height: 250px">
                    </CountriesList>
                </template>
            </FCard>
        </template>
    </Offer>
</template>

<script>
  import CallCentersTable from "@/components/Common/Offer/CallCentersTable";
  import OfferLandings from "@/components/Common/OfferLandings";
  import { momentInst } from "@core/filters";
  import { GET_CALLCENTERS, GET_OFFER, GET_OFFER_LANDINGS, GET_STATISTIC } from "@core/store/action-constants";
  import { SET_EMPTY, UPDATE_FILTERS, UPDATE_PAGINATION } from "@core/store/mutation-constants";
  import { mapActions, mapMutations, mapState } from "vuex";
  import BlockBox from "@/components/Common/BlockBox";
  import Offer from "@/components/Common/Offer/Offer";
  import OfferWebmasters from "@/components/Common/Offer/OfferWebmasters";
  import OfferWebmastersTable from "@/components/Common/Offer/OfferWebmastersTable";
  import Permissions from "@/components/Common/Permissions";
  import OfferAsideStyleComponent from "@/components/Common/Offer/OfferAsideStyleComponent";
  import { OfferService } from "@core/services/admin/offer/OfferService";
  import CountriesList from "@/components/Common/CountriesList";
  import FCard from "@/components/Common/FCard";

  export default {
    name: "OfferWrapper",
    components: {
      OfferAsideStyleComponent,
      FCard,
      Permissions,
      CallCentersTable,
      OfferWebmastersTable,
      CountriesList,
      OfferWebmasters,
      Offer,
      BlockBox,
      OfferLandings
    },
    props: {
      backLink: {
        type: String,
        default: null
      }
    },
    async created () {
      if (this.offerId) {
        this.GET_OFFER_LANDINGS(this.offerId);
        this.GET_OFFER_TRANSITS(this.offerId);
        this.offer = await this.GET_OFFER({ service: OfferService, offerId: this.offerId });
        await this.getStatistics();
        await this.getCallCenters();
        this.UPDATE_LANDING_FILTERS({ isReady: this.isReadyFilter });
        this.UPDATE_TRANSITS_FILTERS({ isReady: this.isReadyFilter });
      }
    },
    data () {
      return {
        offer: null,
        isReadyFilter: true
      };
    },
    computed: {
      isLoading () {
        return {
          offer: this.$wait(`admin/offer/${ GET_OFFER }`),
          transits: this.$wait(`admin/offer/transits/${ GET_OFFER_LANDINGS }`),
          landings:  this.$wait(`admin/offer/landings/${ GET_OFFER_LANDINGS }`),
          callcentres: this.$wait(`admin/${ GET_CALLCENTERS }`),
          statistics: this.$wait(`admin/${ GET_STATISTIC }`)
        };
      },

      ...mapState("admin", {
        statistics: state => state.statistic.statistics,
        trafficTypes: state => state.offer.offer?.offer?.trafficTypes ?? [],
        callCenters: state => state.callCenters.callCenters,
        webmasters: state => state.offer.webmasters.data
      }),

      ...mapState("admin/offer", {
        landings: (state) => state.landings.landings,
        _landingsPagination: (state) => state.landings.pagination,
        transits: (state) => state.transits.landings,
        _transitsPagination: (state) => state.transits.pagination
      }),

      offerAsideStyleComponentOptions () {
        return {
          hasPromo: Boolean(this.offer?.landingUrl || this.offer?.photoUrl),
          trafficAmount: this.trafficTypes?.length ?? 0
        };
      },

      offerId () {
        return this.$route.params.id;
      },

      hasWebmasters () {
        return this.webmasters?.items?.length;
      },

      hasCallCenters () {
        return this.callCenters?.items?.length;
      },

      userTimeZone () {
        return this.$store.getters.timezone;
      },

      transitsCount () {
        return this.transits && this.transits.count > 10;
      },

      // TODO: Временный костыль, чтобы привести рестовский оффер к виду gql оффера
      offerLikeGql () {
        const { offer } = this;
        if (offer) {
          return {
            ...offer,
            finance: {
              webmasterReward: offer.webmasterReward,
              withdrawType: offer.withdrawType
            }
          };
        }
        return null;
      },

      countries () {
        return this.offer?.countries ?? [];
      },

      landingsPagination: {
        get () {
          return this._landingsPagination;
        },

        set (value) {
          this.UPDATE_LANDINGS_PAGINATION(value);
        }
      },

      transitsPagination: {
        get () {
          return this._transitsPagination;
        },

        set (value) {
          this.UPDATE_TRANSITS_PAGINATION(value);
        }
      }
    },
    methods: {
      ...mapActions("admin", [
        GET_CALLCENTERS,
        GET_STATISTIC
      ]),

      ...mapActions("admin/offer/", {
        GET_OFFER,
        GET_OFFER_LANDINGS: `landings/${ GET_OFFER_LANDINGS }`,
        GET_OFFER_TRANSITS: `transits/${ GET_OFFER_LANDINGS }`,
        UPDATE_LANDING_FILTERS: `landings/${ UPDATE_FILTERS }`,
        UPDATE_TRANSITS_FILTERS: `transits/${ UPDATE_FILTERS }`
      }),
      ...mapMutations("admin", [
        SET_EMPTY
      ]),
      ...mapMutations("admin/offer/", {
        UPDATE_LANDINGS_PAGINATION: `landings/${ UPDATE_PAGINATION }`,
        UPDATE_TRANSITS_PAGINATION: `transits/${ UPDATE_PAGINATION }`
      }),
      async getStatistics () {
        await this.GET_STATISTIC({
          groups: ["OFFER"],
          filters: {
            dateStart: momentInst().startOf("day").subtract(7, "day").toDate(),
            dateEnd: momentInst().endOf("day").toDate(),
            offerId: this.offerId
          }
        });
      },
      async getCallCenters (activeTab) {
        await this.GET_CALLCENTERS({
          filters: {
            offers: this.offerId
          },
          limit: 100,
          offset: 0,
          timeZone: activeTab ? null : this.userTimeZone?.offset
        });
      }
    },
    beforeDestroy () {
      this.SET_EMPTY();
    }
  };
</script>

<style scoped>

</style>
