<template>
    <div
        v-loading="!offer"
        class="flex mb-0 laptop:mb-6">
        <FCard class="w-full overflow-hidden offer-common">
            <template
                v-if="offer"
                #title>
                <div class="w-full flex justify-between flex-wrap my-4 laptop:px-8 laptop:py-4 laptop:border-b">
                    <div
                        class="w-full tablet:w-1/2">
                        <span class="break-words text-2xl font-bold ">{{ offer.name }}</span>
                    </div>
                    <div
                        class="w-full text-left tablet:w-1/2 f-col tablet:text-right">
                        <div class="text-xs w-full">
                            {{ `ID: ${ offer.id }` }}
                        </div>
                        <div
                            v-if="role === 'webmaster'"
                            class="text-xs text-gray-text">
                            {{ $t(`webmaster.offer.metrics.statusType.${ offer.isWebmasterJoinedToOffer }`) }}
                        </div>
                    </div>
                </div>
            </template>
            <template
                v-if="offer">
                <div class="mobile-image h-80 overflow-hidden tablet:mb-8 laptop:mb-0 laptop:hidden">
                    <img
                        class="w-full"
                        :src="image"
                        :alt="offer.name">
                </div>

                <div class="laptop:hidden">
                    <slot name="button"></slot>
                </div>

                <div class="metrics">
                    <div class="metrics-item">
                        <span class="metrics-item_header">{{ $t("webmaster.offer.metrics.vertical") }}:</span>
                        <span>{{ offer.categories[0].name }}</span>
                    </div>
                    <div class="metrics-item">
                        <span class="metrics-item_header">{{ $t("webmaster.offer.metrics.goal") }}:</span>
                        <span> {{ $t(`webmaster.offer.metrics.goalTypes.${ offer.withdrawType.toLowerCase() }`) }}</span>
                    </div>
                    <!--                    <div class="metrics-item">-->
                    <!--                        <span class="metrics-item_header">{{ $t("webmaster.offer.metrics.goalPrice") }}:</span>-->
                    <!--                        <span>{{ formatCurrency(offer.webmasterReward) }}</span>-->
                    <!--                    </div>-->
                <!--                    <div class="metrics-item">-->
                <!--                        <span class="metrics-item_header">{{ $t("webmaster.offer.metrics.geo") }}:</span>-->
                <!--                        <Countries-->
                <!--                            :items="offer.countries"-->
                <!--                            compact></Countries>-->
                <!--                    </div>-->
                </div>
            </template>
        </FCard>
        <div
            v-if="offer"
            class="h-auto">
            <img
                class="object-cover logo w-60 hidden rounded-r laptop:block shadow-lg"
                :src="image"
                :alt="offer.name">
        </div>
        <div
            v-else
            class="h-auto">
            <img
                class="object-cover logo w-60 hidden rounded-r laptop:block shadow-lg"
                :src="image"
                alt="">
        </div>
    </div>
</template>

<script>
  import { formatEmptyString, formatCurrency } from "@core/filters";
  import FCard from "@/components/Common/FCard";
  import { mapGetters } from "vuex";
  // import Countries from "@/components/Common/Countries";

  export default {
    name: "OfferCommon",
    components: {
      FCard
      // Countries
    },
    props: {
      offer: {
        type: Object,
        default: null
      }
    },
    
    computed: {
      image () {
        return this.offer?.logo?.url ?? require("../../../assets/Offer/default.png");
      },
      ...mapGetters([
        "role"
      ])
    },
    
    methods: {
      coloredStatus (status) {
        return status ? "is-success" : "is-danger";
      },
      formatCurrency,
      formatEmptyString
    }
  };
</script>

<style lang="scss" scoped>
    .logo {
        height: 100%;
    }
    .metrics {
        margin-top: -1.5em;
        @apply w-full flex flex-wrap px-0 py-4 laptop:px-8 laptop:pb-4;

        .metrics-item {
            @apply w-full flex laptop:w-1/4 flex-col;
        }

        .metrics-item_header {
            @apply w-full text-xs text-gray-text;
        }
    }
    
    ::v-deep {
        .header {
            @media screen and (max-width: 768px) {
                display: flex;
                flex-direction: column;
                align-items: start;
    
                .filters {
                    justify-content: flex-start;
                }
            }
        }

      .card {
        padding: 0 !important;
          height: auto;
      }
    }

    .offer-common {
      margin-bottom: 0 !important;
      @media screen and (min-width: 768px) {
        border-radius:  0.25rem 0 0  0.25rem !important;
      }
    }
</style>
