<template>
    <div>
        <div
            v-if="backLink"
            class="backLink mx-4 mb-4 mt-6 laptop:mt-4">
            <router-link
                :to="backLink"
                class="table-item--link">
                <i
                    class="el-icon-arrow-left text-red-border"></i>
                {{ $t(`common.entity.offer.goBack`) }}
            </router-link>
        </div>

        <el-row>
            <el-col class="w-full laptop:pr-4 laptop:w-3/4">
                <OfferCommon :offer="offer">
                    <template #button>
                        <slot name="aside"></slot>
                    </template>
                </OfferCommon>
              
                <FCard
                    v-if="offer"
                    class="mb-0">
                    <template #title>
                        <span class="f-col text-xl font-bold"> {{ $t('webmaster.offer.description') }} </span>
                    </template>
                    <div class="break-words mb-4">
                        {{ offer.description }}
                    </div>
                </FCard>

                <FCard v-loading="!trafficTypes.length">
                    <template #header>
                        <span class="f-col text-xl font-bold"> {{ $t('webmaster.offer.rules') }} </span>
                    </template>
                    <template>
                        <div
                            v-if="trafficTypes"
                            class="rules">
                            <div
                                :key="index"
                                v-for="(trafficType, index) in trafficTypes"
                                class="rules-item">
                                <span
                                    class="rules-item_header"
                                    :class="{'rules-item_banned': isBanned(trafficType)}"> {{ trafficType.name }} </span>
                                <div
                                    v-if="isBanned(trafficType)"
                                    class="rules-item_body">
                                    <FSvgIcon
                                        icon="times"
                                        class="mr-2"></FSvgIcon> {{ $t("webmaster.offer.banned") }}
                                </div>
                                <div
                                    v-else
                                    class="rules-item_body">
                                    <FSvgIcon
                                        icon="check"
                                        color="#1BD12D"
                                        class="mr-2"></FSvgIcon> {{ $t("webmaster.offer.allowed") }}
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="offer && offer.rules"
                            class="mt-4 f-col break-words">
                            <div class="w-full text-sm mb-2 text-gray-400">
                                {{ $t("common.entity.offer.comment") }}:
                            </div>
                            <span v-html="offer.rules"></span>
                        </div>
                    </template>
                </FCard>
            </el-col>

            <el-col class="w-full laptop:w-1/4">
                <el-aside
                    width="100%"
                    class="hidden pr-2 laptop:block desktop:pr-0">
                    <slot
                        name="aside">
                    </slot>
                </el-aside>
            </el-col>

            <el-col class="w-full laptop:pr-4 laptop:w-3/4">
                <slot name="content"></slot>
            </el-col>
        </el-row>
        <OfferEditLinkModal></OfferEditLinkModal>
    </div>
</template>

<script>
  import OfferCommon from "@/components/Common/Offer/OfferCommon";
  import FCard from "@/components/Common/FCard";
  import FSvgIcon from "@/components/Common/FSvgIcon";
  import { mapActions, mapState } from "vuex";
  import { GET_TRAFFIC_TYPES } from "@core/store/action-constants";
  import OfferEditLinkModal from "@/components/Webmaster/Offer/OfferEditLinkModal";

  export default {
    name: "Offer",
    components: {
      OfferEditLinkModal,
      OfferCommon,
      FCard,
      FSvgIcon
    },
    props: {
      offer: {
        type: Object,
        default: null
      },
      bannedTrafficTypes: {
        type: Array,
        default: null
      },
      backLink: {
        type: String,
        default: null
      }
    },
    created () {
      this.GET_TRAFFIC_TYPES();
    },
    computed: {
      ...mapState("trafficTypes", {
        trafficTypes: ({ trafficTypes }) => trafficTypes
      }),

      hasPromo () {
        return Boolean(this.offer?.landingUrl || this.offer?.photoUrl);
      },

      hasTrafficTypes () {
        return this.bannedTrafficTypes?.length;
      }
    },
    methods: {
      ...mapActions("trafficTypes", {
        GET_TRAFFIC_TYPES
      }),
      isBanned (trafficType) {
        return this.bannedTrafficTypes.findIndex((item) => item.id === trafficType.id) > -1;
      }
    },
    watch: {
      trafficTypes (value) {
        const count = value.length;
        this.countOfRulesRows = count > 1 ? count : 1;
      }
    }
  };
</script>

<style lang="scss" scoped>
    .rules {
        @apply w-full flex flex-wrap bg-white shadow-md;

        .rules-item {
            @apply w-full flex p-6 tablet:w-1/2 laptop:w-1/3 desktop:w-1/4 flex-col overflow-hidden;
            border-bottom: 1px solid #eeeeee;
        }

        .rules-item_header {
            @apply w-full text-lg mb-2 font-semibold
        }

        .rules-item_body {
            @apply text-sm flex items-center
        }

        .rules-item_banned {
            color: #FE2615
        }
    }
</style>
