<template>
    <ul>
        <template v-if="items && items.length">
            <template v-if="compact && items.length > 1">
                <div class="flex">
                    <ul>
                        <li>
                            <div>
                                <!--suppress HtmlUnknownTag -->
                                <flag :iso="items[0].alpha2"></flag>
                                {{ items[0].name }}
                            </div>
                        </li>
                    </ul>
                    <LTooltip
                        position="bottom"
                        interactive
                        ignore-flip
                        type="white">
                        <el-tag>+{{ items.length - 1 }}</el-tag>
                        <template #content>
                            <div
                                :style="{ 'max-height': `${ maxHeight }px` }"
                                class="tooltip-content-list">
                                <ul>
                                    <li
                                        :key="index"
                                        v-for="(country, index) in items"
                                        class="my-1">
                                        <div>
                                            <!--suppress HtmlUnknownTag -->
                                            <flag :iso="country.alpha2"></flag>
                                            {{ country.name }}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </template>
                    </LTooltip>
                    <!--                    <el-popover-->
                    <!--                        trigger="click"-->
                    <!--                        :placement="tooltipPosition"-->
                    <!--                        multilined>-->
                    <!--                        <span class="">-->
                    <!--                            +{{ items.length - 1 }}-->
                    <!--                        </span>-->
                    <!--                        <template #content>-->
                    <!--                            <div-->
                    <!--                                :style="{ 'max-height': `${ maxHeight }px` }"-->
                    <!--                                class="tooltip-content-list">-->
                    <!--                                <ul>-->
                    <!--                                    <li-->
                    <!--                                        :key="index"-->
                    <!--                                        v-for="(country, index) in items"-->
                    <!--                                        class="my-1">-->
                    <!--                                        <div>-->
                    <!--                                            &lt;!&ndash;suppress HtmlUnknownTag &ndash;&gt;-->
                    <!--                                            <flag :iso="country.alpha2"></flag>-->
                    <!--                                            {{ country.name }}-->
                    <!--                                        </div>-->
                    <!--                                    </li>-->
                    <!--                                </ul>-->
                    <!--                            </div>-->
                    <!--                        </template>-->
                    <!--                    </el-popover>-->
                </div>
            </template>
            <template v-else>
                <li
                    :key="index"
                    v-for="(country, index) in items">
                    <div>
                        <!--suppress HtmlUnknownTag -->
                        <flag :iso="country.alpha2"></flag>
                        {{ country.name }}
                    </div>
                </li>
            </template>
        </template>
        <li v-else>
            {{ empty ? empty : $options.methods.formatEmptyString() }}
        </li>
    </ul>
</template>

<script>
  import { ListView } from "@core/mixins";
  
  export default {
    name: "Countries",
    mixins: [ListView],
    props: {
      compact: {
        type: Boolean,
        default: false
      },
      tooltipPosition: {
        type: String,
        default: "left"
      },
      maxHeight: {
        type: [String, Number],
        default: 200
      }
    }
  };
</script>

<style lang="scss" scoped>
    .tooltip-content-list {
        margin: -0.35rem -0.75rem;
        padding: 0.35rem 0.75rem;
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 2;
    }

    ul {
        margin: 0 !important;
        list-style: none !important;

        .tippy {
          display: flex;
        }

        li {
            padding: 0;

            div {
                display: flex;
                text-align: left;
                align-items: center;
                justify-content: left;

                .flag-icon {
                    flex: none;
                    margin-right: 0.4em;
                }
            }
        }
    }

    ul li div .flag-icon[data-v-2ccd4fa0] {
        width: 1.75rem !important;
        height: 1.25rem !important;
    
        box-shadow: 0 0 0 1px #dee3ec;
        box-sizing: border-box;
        border-radius: 4px;
        background-size: cover;
    }

    .el-popper {
        width: auto;
        padding: 0.35rem 0.75rem;
        border-radius: 10px;
        font-size: 0.85rem;
        font-weight: 400;
        box-shadow: 0px 1px 2px 1px rgb(0 1 0 / 20%);
        z-index: 38;
        white-space: nowrap;
        position: absolute;
    }

    .el-tag {
        display: inline-flex;
        align-items: center;
        justify-content: center;
    
        height: 2em;
        margin-left: .5rem;
        padding-left: 0.75em;
        padding-right: 0.75em;
        color: #ffba40;
        font-size: 0.75rem;
        border-radius: 5px;
        background-color: whitesmoke;
        line-height: 1.5;
        white-space: nowrap;
        border: none !important;
        cursor: pointer;
    }
</style>
