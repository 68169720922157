<template>
    <div class="columns is-multiline">
        <div class="column is-2">
            <FCountrySelect
                v-model="filters.countries"
                multiple
                :placeholder="$t(`webmaster.offers.filters.labels.country`)">
            </FCountrySelect>
        </div>
        <div class="column is-2">
            <b-field>
                <FSelect
                    v-model="filters.offerId"

                    :get-data-vuex="getOffers"
                    :min-required-length="1"
                    :placeholder="$t(`admin.users.webmasters.modal.details.header.filters.labels.offer`)">
                    <template #text="option">
                        <OfferOutput :offer="option.option"></OfferOutput>
                    </template>
                    <template #selected-option="option">
                        <OfferOutput :offer="option.option"></OfferOutput>
                    </template>
                </FSelect>
            </b-field>
        </div>
        <!--<div class="column has-text-right">
            <b-button type="is-danger is-light" @click="clearFilters">
                {{ $t(`common.buttons.filtersReset`) }}
            </b-button>
        </div>-->
    </div>
</template>

<script>
  import FCountrySelect from "@/components/Common/FCountrySelect";
  import OfferOutput from "@/components/Common/OfferOutput";
  import FSelect from "@/components/Common/FSelect";
  import { Filters, OffersFilters, UsersFilters } from "@core/mixins";
  import { GET_INITIAL_OFFERS, GET_OFFERS_LIST } from "@core/store/action-constants";
  import { UPDATE_OFFERS_FILTERS, UPDATE_OFFERS_LIST_PAGINATION } from "@core/store/mutation-constants";
  import { mapActions, mapMutations, mapState } from "vuex";

  export default {
    name: "IndividualRateFilters",
    mixins: [Filters, UsersFilters, OffersFilters],
    components: {
      FCountrySelect,
      FSelect,
      OfferOutput
    },
    props: {
      webmaster: {
        type: Object,
        default: null
      }
    },
    computed: {
      ...mapState("admin/users/webmasters/detail/rates", {
        offersList: state => state.offersList.data
      })
    },
    methods: {
      ...mapMutations("admin/users/webmasters/detail/rates", {
        UPDATE_OFFERS_LIST_PAGINATION: `offersList/${ UPDATE_OFFERS_LIST_PAGINATION }`,
        UPDATE_OFFERS_FILTERS: `offersList/${ UPDATE_OFFERS_FILTERS }`
      }),

      ...mapActions("admin/users/webmasters/detail/rates", {
        GET_OFFERS_LIST: `offersList/${ GET_OFFERS_LIST }`,
        GET_INITIAL_OFFERS: `offersList/${ GET_INITIAL_OFFERS }`
      }),

      async getOffers (label = "") {
        this.UPDATE_OFFERS_LIST_PAGINATION();
        this.UPDATE_OFFERS_FILTERS({
          nameOrIntId: label.length > 0 ? label : null,
          webmaster: this.webmaster.id
        });
        if (this.offersList?.items?.length > 0) {
          await this.GET_OFFERS_LIST();
        } else {
          const res = await this.GET_INITIAL_OFFERS(this.$route.query.offer);
          if (res?.length > 0) {
            this.offer = res[0];
          }
        }
        return this.offersList;
      },

      clearFilters () {
        this.selected = [];
        this.emitClear();
      }
    }
  };
</script>

<style lang="scss" scoped>
    .column {
        flex-basis: 240px;
    }
</style>
