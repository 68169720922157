<template>
    <th
        v-if="isHeader"
        :class="rowField.titleClass"
        @click="onColumnHeaderClicked(rowField, $event)">
        <!--        rowData: {{ rowData }}-->
        <!--        rowIndex: {{ rowIndex }}-->
        <!--        rowField: {{ rowField }}-->
        <!--        isHeader: {{ isHeader }}-->
        <!--        title: {{ title }}-->
        <div v-html="title"></div>
    </th>
    <td v-else>
        <OfferOutput :offer="offer"></OfferOutput>
    </td>
</template>

<script>
  import OfferOutput from "@/components/Common/OfferOutput";
  import { StatisticTableView } from "@core/mixins";
  
  export default {
    name: "StatisticTableViewOfferOutput",
    mixins: [StatisticTableView],
    components: { OfferOutput },
    
    computed: {
      offer () {
        const {
          rowData,
          rowField: { label }
        } = this;
        
        return this.formatter(this.getMethodByPath(rowData, label));
      }
    }
  };
</script>

<style lang="scss" scoped>
    th {
        padding: 0.8em 0.5em;

        & > div {
            display: flex;
            white-space: nowrap;

            ::v-deep {
                i {
                    margin-left: 0.5em;
                }
            }
        }
    }

    .v-popover {
        vertical-align: middle;
    }

    .image {
        &.is-38x38 {
            $size: 38px;

            width: $size;
            height: $size;
            max-width: $size;
            min-width: $size;
        }
    }
</style>
