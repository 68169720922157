<template>
    <div class="columns is-multiline">
        <Permissions :permissions="['ADVERTISERS.LIST.ALL', 'ADVERTISERS.LIST.OWN', 'ADVERTISERS.LIST.UNBIND']">
            <div class="column is-2-desktop is-3">
                <FSelect
                    v-model="filters.advertiserId"
                    :get-data="getAdvertisers"
                    field="login"
                    :placeholder="$t(`admin.users.webmasters.modal.details.header.filters.labels.advertiser`)">
                    <template #text="{ option }">
                        <UserOutput :user="option"></UserOutput>
                    </template>
                    <template #selected-option="{ option }">
                        <UserOutput :user="option"></UserOutput>
                    </template>
                </FSelect>
            </div>
        </Permissions>
        <div class="column is-2-desktop is-3">
            <FSelect
                v-model="filters.offerId"
                :get-data="getOffers"
                :min-required-length="1"
                :placeholder="$t(`admin.users.webmasters.modal.details.header.filters.labels.offer`)">
                <template #text="{ option }">
                    <OfferOutput :offer="option"></OfferOutput>
                </template>
                <template #selected-option="{ option }">
                    <OfferOutput :offer="option"></OfferOutput>
                </template>
            </FSelect>
        </div>
        <div class="column is-2-desktop is-3">
            <FSelect
                v-model="filters.groups[0]"
                :get-data="getGroups"
                :placeholder="$t(`admin.users.webmasters.modal.details.header.filters.labels.group`)"
                field="label"
                prop="value">
            </FSelect>
        </div>
        <div class="column is-narrow">
            <CurrencyCheckbox v-model="filters.currency"></CurrencyCheckbox>
        </div>
        <div class="column is-narrow">
            <CustomDatepicker
                v-model="filters.datepicker"
                class="is-pulled-right"
                label="">
            </CustomDatepicker>
        </div>
        <!--<div class="column has-text-right">
            <b-button type="is-danger is-light" @click="clearFilters">
                {{ $t(`common.buttons.filtersReset`) }}
            </b-button>
        </div>-->
    </div>
</template>

<script>
  import CurrencyCheckbox from "@/components/Common/CurrencyCheckbox";
  import OfferOutput from "@/components/Common/OfferOutput";
  import Permissions from "@/components/Common/Permissions";
  import FSelect from "@/components/Common/FSelect";
  import UserOutput from "@/components/Common/UserOutput";
  import { Filters, OffersFilters, UsersFilters } from "@core/mixins";
  import { UPDATE_OFFERS_DICTIONARY } from "@core/store/action-constants";
  import { UPDATE_ADVERTISERS_DICTIONARY } from "@core/store/mutation-constants";
  import { mapActions } from "vuex";
  import CustomDatepicker from "@/components/Common/CustomDatepicker";

  export default {
    name: "DetailsFilters",
    mixins: [Filters, UsersFilters, OffersFilters],
    components: {
      Permissions,
      CustomDatepicker,
      CurrencyCheckbox,
      FSelect,
      OfferOutput,
      UserOutput
    },
    computed: {
      groups () {
        return [
          {
            label: this.$t("admin.users.webmasters.modal.details.header.filters.values.day"),
            value: "day"
          },
          {
            label: this.$t("admin.users.webmasters.modal.details.header.filters.values.month"),
            value: "month"
          }
        ];
      }
    },
    methods: {
      ...mapActions("admin", [
        UPDATE_ADVERTISERS_DICTIONARY,
        UPDATE_OFFERS_DICTIONARY
      ]),

      getAdvertisers (offset = 0, label = "") {
        return this.getUsersByActionName(this.UPDATE_ADVERTISERS_DICTIONARY, label, offset);
      },

      getGroups () {
        return new Promise(resolve => {
          resolve({ count: this.groups.length, items: this.groups });
        });
      },

      getOffers (offset = 0, label = "") {
        return this.getOffersByActionName(this.UPDATE_OFFERS_DICTIONARY, label, offset);
      },

      clearFilters () {
        this.emitClear();
      }
    }
  };
</script>

<style lang="scss" scoped>
    .column {
        flex-basis: 240px;
    }
</style>
