<template>
    <TablePlaceholder :data="data">
        <template>
            <CustomizableTable
                ref="customizableTable"
                :data-manager="dataManager"
                :fields="fields"
                :is-loading="isLoading"
                :sort-order="sortOrder">
                <template slot="tableHeader">
                    <StatisticTableHeader :styling="headerStyling">
                        <template slot-scope="{ fieldName: name }">
                            <div class="table-header">
                                <label>
                                    {{ $t(`webmaster.statistics.statistics.table.labels.${ name }`) }}
                                </label>
                                <!--                        <button class="icon-btn">-->
                                <!--                            <b-icon icon="bars" size="is-small"></b-icon>-->
                                <!--                        </button>-->
                            </div>
                        </template>
                    </StatisticTableHeader>
                    <VuetableRowHeader></VuetableRowHeader>
                </template>
                <template #tableFooter="{ fields }">
                    <StatisticTableFooter
                        v-if="data.extras.group.length"
                        :average="data.aggregates.average"
                        :fields="fields"
                        :total="data.aggregates.total">
                    </StatisticTableFooter>
                </template>
            </CustomizableTable>
        </template>
    </TablePlaceholder>
</template>

<script>
  import CustomizableTable from "@/components/Common/Table/CustomizableTable";
  import { formatCurrency, formatPercent, toFixed } from "@core/filters";
  import { Table } from "@core/mixins";
  import { GET_STATISTIC } from "@core/store/action-constants";
  import _orderBy from "lodash/orderBy";
  import { mapActions } from "vuex";
  import StatisticTableFooter from "@/components/Common/Table/StatisticTableFooter";
  import StatisticTableHeader from "@/components/Common/Table/StatisticTableHeader";
  import TablePlaceholder from "@/components/Common/Table/TablePlaceholder";
  import StatisticTableViewGroup from "@/components/Common/Table/View/StatisticTableViewGroup";
  import StatisticTableViewIconsItem from "@/components/Common/Table/View/StatisticTableViewIconsItem";
  import VuetableMixin from "@/components/Common/Table/VuetableMixin";
  
  export default {
    name: "DetailsTable",
    mixins: [Table, VuetableMixin],
    components: {
      StatisticTableFooter,
      StatisticTableHeader,
      TablePlaceholder,
      CustomizableTable
    },
    props: {
      currency: {
        type: String,
        default: null
      }
    },
    data () {
      return {
        sortOrder: []
      };
    },
    computed: {
      fields () {
        if (!this.data) {
          return [];
        }
        const common = {
          name: StatisticTableViewGroup,
          groupName: "groups",
          titleClass: "has-text-centered has-text-grey-light",
          dataClass: "has-text-centered"
        };
        const keys = this.data.extras.group || [];
        const groups = keys.reduce((groups, key) => {
          if (key === "offer") {
            const offerGroups = [
              {
                ...common,
                label: "offer.name",
                sortField: "offer.name",
                title: this.$t("webmaster.statistics.statistics.filters.values.groupsList.offer")
              }
            ];

            groups.push(...offerGroups);

          } else if (key === "advertiser") {
            groups.push({
              ...common,
              label: `${ key }.login`,
              sortField: `${ key }.login`,
              title: this.$t(`webmaster.statistics.statistics.filters.values.groupsList.${ key }`)
            });

          } else if (key === "flow") {
            groups.push({
              ...common,
              label: `${ key }.name`,
              sortField: `${ key }.name`,
              title: this.$t(`webmaster.statistics.statistics.filters.values.groupsList.${ key }`)
            });
          } else if (key === "bunch") {
            groups.push({
              ...common,
              label: `${ key }.id`,
              sortField: `${ key }.id`,
              title: this.$t(`webmaster.statistics.statistics.filters.values.groupsList.${ key }`)
            });
          } else {
            groups.push({
              ...common,
              label: key,
              sortField: key,
              title: this.$t(`webmaster.statistics.statistics.filters.values.groupsList.${ key }`)
            });
          }

          return groups;
        }, []);

        return [
          ...groups,

          {
            ...common,
            name: StatisticTableViewIconsItem,
            groupName: "leads",
            label: "countLeadsCreated",
            sortField: "countLeadsCreated",
            title: this.$t("webmaster.statistics.statistics.table.labels.leads"),
            icon: "leads"
          },
          {
            ...common,
            name: StatisticTableViewIconsItem,
            groupName: "leads",
            label: "countLeadsCancelled",
            sortField: "countLeadsCancelled",
            title: this.$t("webmaster.statistics.statistics.table.labels.leadCancelled"),
            icon: "leadCancelled"
          },
          {
            ...common,
            name: StatisticTableViewIconsItem,
            groupName: "leads",
            label: "countLeadsAccepted",
            sortField: "countLeadsAccepted",
            title: this.$t("webmaster.statistics.statistics.table.labels.leadConfirmed"),
            icon: "leadConfirmed"
          },
          {
            ...common,
            name: StatisticTableViewIconsItem,
            groupName: "leads",
            label: "countLeadsProcessing",
            sortField: "countLeadsProcessing",
            title: this.$t("webmaster.statistics.statistics.table.labels.leadProcessed"),
            icon: "leadProcessed"
          },
          {
            ...common,
            name: StatisticTableViewIconsItem,
            groupName: "leads",
            label: "countLeadsSpammed",
            sortField: "countLeadsSpammed",
            title: this.$t("webmaster.statistics.statistics.table.labels.leadTrash"),
            icon: "leadTrash"
          },

          {
            ...common,
            name: StatisticTableViewIconsItem,
            groupName: "approve",
            label: "conversionApprove",
            sortField: "conversionApprove",
            title: this.$t("webmaster.statistics.statistics.table.labels.approve"),
            icon: "approve",
            formatter: (value) => toFixed(formatPercent(value), 2)
          },
          {
            ...common,
            name: StatisticTableViewIconsItem,
            groupName: "approve",
            label: "conversionCleanApprove",
            sortField: "conversionCleanApprove",
            title: this.$t("webmaster.statistics.statistics.table.labels.approveClean"),
            icon: "approveClean",
            formatter: (value) => toFixed(formatPercent(value), 2)
          },

          {
            ...common,
            name: StatisticTableViewIconsItem,
            groupName: "income",
            label: "moneyWebmasterAccrued",
            sortField: "moneyWebmasterAccrued",
            title: this.$t("webmaster.statistics.statistics.table.labels.accrued"),
            icon: "accrued",
            formatter: value => formatCurrency(value, this.currency)
          },
          {
            ...common,
            name: StatisticTableViewIconsItem,
            groupName: "income",
            label: "moneyWebmasterAwaiting",
            sortField: "moneyWebmasterAwaiting",
            title: this.$t("webmaster.statistics.statistics.table.labels.awaiting"),
            icon: "awaiting",
            formatter: value => formatCurrency(value, this.currency)
          }
        ];
      }
    },
    methods: {
      ...mapActions("admin", [
        GET_STATISTIC
      ]),
  
      dataManager (sortOrder) {
        // noinspection UnnecessaryLocalVariableJS
        const ordered = _orderBy(this.data.items, sortOrder.map(el => el.sortField), sortOrder.map(el => el.direction));
    
        return ordered;
      },
  
      headerStyling (name) {
        switch (name) {
          case "groups":
            return {
              "color": "black",
              "background-color": "#F7F7F7"
            };
          case "leads":
            return {
              "color": "white",
              "background-color": "#7adde2"
            };
          case "approve":
            return {
              "color": "white",
              "background-color": "#91e3d7"
            };
          case "income":
            return {
              "color": "white",
              "background-color": "#7adde2"
            };
          case "landing":
            return {
              "color": "white",
              "background-color": "#91e3d7"
            };
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
    @mixin table-shadow($color, $size, $positions) {
        @each $position in $positions {
            &:nth-last-child(#{$position}) {
                position: relative;
            }

            &:nth-last-child(#{$position}):before {
                content: "";
                top: -1px;
                z-index: 1;
                left: -$size;
                width: $size;
                position: absolute;
                pointer-events: none;
                height: calc(100% + 1px);
                box-shadow: inset (-$size) 0 $size (-$size) $color;
            }
        }
    }

    ::v-deep {
        .sort-icon {
            line-height: 1.5;
        }

        .tippy {
            & + span {
                .sort-icon {
                    line-height: 2.4;
                }
            }
        }

        table {
            thead, tbody {
                background-color: white;
            }

            thead {
                tr {
                    &:first-child {
                        th {
                            padding: 0;

                            > div {
                                display: flex;
                                padding: 20px;
                                align-items: center;
                                justify-content: space-between;

                                label {
                                    text-transform: uppercase;
                                }
                            }
                        }
                    }
                }
            }
        }

        .b-table {
            $color: rgba(0, 0, 0, 0.1);
            $size: 20px;
            $positionsHeaders: (1, 2, 3, 4);
            $positionsColumns: (2, 4, 9, 13);

            thead {
                tr {
                    &:first-child {
                        th {
                            @include table-shadow($color, $size, $positionsHeaders);
                        }
                    }

                    th {
                        @include table-shadow($color, $size, $positionsColumns);
                    }
                }
            }

            tbody {
                tr {
                    td {
                        @include table-shadow($color, $size, $positionsColumns);
                    }
                }
            }

            tfoot {
                tr {
                    td {
                        @include table-shadow($color, $size, $positionsColumns);
                    }
                }

            }
        }
        .card {
          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
        }
    }

    .icon-btn {
        border: none;
        outline: none;
        cursor: pointer;
        background-color: transparent;
    }
</style>
