<template>
    <TablePlaceholder :data="data">
        <template>
            <CustomizableTable
                ref="customizableTable"
                :css="styling.table"
                :data-manager="dataManager"
                :fields="fields"
                :sort-order="sortOrder"
                :is-loading="isLoading">
                <template #[`individualRate.advertiserPayment`]="{ data }">
                    <span>{{ formatAdvertiserCost(data.rowData.individualRate.advertisers) }}</span>
                </template>
                <template #[`individualRate.commission`]="{ data }">
                    <span>{{
                        formatCommission(
                            data.rowData.individualRate.webmasterMoneyReward,
                            data.rowData.individualRate.offerMoneyReward,
                            data.rowData.individualRate.advertisers
                        )
                    }}</span>
                </template>
                <template slot="tableHeader">
                    <StatisticTableHeader :styling="headerStyling">
                        <template slot-scope="{ fieldName: name }">
                            <div class="table-header">
                                <label>
                                    {{ $t(`admin.users.webmasters.modal.details.body.individualRates.labels.${ name }`) }}
                                </label>
                                <!--                        <button class="icon-btn">-->
                                <!--                            <b-icon icon="bars" size="is-small"></b-icon>-->
                                <!--                        </button>-->
                            </div>
                        </template>
                    </StatisticTableHeader>
                    <VuetableRowHeader></VuetableRowHeader>
                </template>
                <!--                <template #tableFooter="{ fields }">-->
                <!--                    <StatisticTableFooter v-if="data.groups.length" :average="data.average" :fields="fields"-->
                <!--                                          :total="data.total">-->
                <!--                    </StatisticTableFooter>-->
                <!--                </template>-->
            </CustomizableTable>
        </template>
    </TablePlaceholder>
</template>

<script>
  import CustomizableTable from "@/components/Common/Table/CustomizableTable";
  import { formatCurrency, toFixed } from "@core/filters";
  import { Table } from "@core/mixins";
  import { GET_STATISTIC } from "@core/store/action-constants";
  import _orderBy from "lodash/orderBy";
  import { mapActions } from "vuex";
  import StatisticTableHeader from "@/components/Common/Table/StatisticTableHeader";
  import TablePlaceholder from "@/components/Common/Table/TablePlaceholder";
  import StatisticTableViewCountry from "@/components/Common/Table/View/StatisticTableViewCountry";
  import StatisticTableViewGroup from "@/components/Common/Table/View/StatisticTableViewGroup";
  import StatisticTableViewNumber from "@/components/Common/Table/View/StatisticTableViewNumber";
  import StatisticTableViewOfferOutput from "@/components/Common/Table/View/StatisticTableViewOfferOutput";
  import VuetableMixin from "@/components/Common/Table/VuetableMixin";
  
  export default {
    name: "IndividualRateTable",
    mixins: [Table, VuetableMixin],
    components: {
      StatisticTableHeader,
      TablePlaceholder,
      CustomizableTable
    },
    
    data () {
      return {
        sortOrder: [],
        headers: [
          {
            name: "webmasterRate",
            colspan: 4
          }
        ]
      };
    },
    computed: {
      fields () {
        if (!this.data) return [];
        const common = {
          name: StatisticTableViewGroup,
          titleClass: "has-text-left has-text-grey-light text-base break-normal",
          label: "individualRate.groups",
          dataClass: "has-text-left"
        };

        return [
          {
            ...common,
            groupName: "individualRate",
            name: StatisticTableViewCountry,
            label: "individualRate.countries",
            sortField: "individualRate.countries",
            width: "15%",
            title: this.$t("admin.users.webmasters.modal.details.body.individualRates.labels.country")
          },
          {
            ...common,
            groupName: "individualRate",
            name: StatisticTableViewOfferOutput,
            label: "individualRate.offer",
            sortField: "individualRate.offer.name",
            title: this.$t("admin.users.webmasters.modal.details.body.individualRates.labels.offer")
          },
          {
            ...common,
            groupName: "individualRate",
            name: StatisticTableViewNumber,
            label: "individualRate.offerMoneyReward",
            sortField: "individualRate.offerMoneyReward.value",
            dataClass: "has-text-right",
            width: "1%",
            title: this.$t("admin.users.webmasters.modal.details.body.individualRates.labels.offerRate"),
            formatter: value => formatCurrency(value?.value, value?.currency)
          },
          {
            ...common,
            groupName: "individualRate",
            name: StatisticTableViewNumber,
            label: "individualRate.webmasterMoneyReward",
            sortField: "individualRate.webmasterMoneyReward.value",
            dataClass: "has-text-right",
            width: "1%",
            title: this.$t("admin.users.webmasters.modal.details.body.individualRates.labels.webmasterRate"),
            formatter: value => formatCurrency(value?.value, value?.currency)
          },
          {
            ...common,
            groupName: "individualRate",
            name: "individualRate.advertiserPayment",
            width: "1%",
            title: this.$t("admin.users.webmasters.modal.details.body.individualRates.labels.advertiserPayment")
          },
          {
            ...common,
            groupName: "individualRate",
            name: "individualRate.commission",
            dataClass: "has-text-right",
            width: "1%",
            title: this.$t("admin.users.webmasters.modal.details.body.individualRates.labels.commission")
          }
        ];
      }
    },
    methods: {
      ...mapActions("admin", [
        GET_STATISTIC
      ]),

      toFixed,

      dataManager (sortOrder) {
        const items = this.data.items.map(item => {
          const { offerMoneyReward, webmasterMoneyReward, offer: { name, intId, countries, advertisers } } = item;
          return {
            individualRate: {
              offer: { name, intId },
              countries,
              advertisers,
              offerMoneyReward,
              webmasterMoneyReward,
              // FixMe: Проверка нуля - это ограничение бэка, когда этот вопрос решится нужно будет что то предпринять
              commission: webmasterMoneyReward === 0 ? null : webmasterMoneyReward - offerMoneyReward
            }
          };
        });

        return _orderBy(items, sortOrder.map(el => el.sortField), sortOrder.map(el => el.direction));
      },

      headerStyling (name) {
        switch (name) {
          case "individualRate":
            return {
              "color": "black",
              "background-color": "#F7F7F7"
            };
        }
      },

      formatAdvertiserCost (advertisers) {
        switch (advertisers.length) {
          case 0:
            return "-";
          case 1:
            return this.formatCurrency(advertisers[0].writeOff.value, advertisers[0].writeOff.currency);
          default: {
            const writeOffs = advertisers.map(advertiser => parseFloat(advertiser?.writeOff.value));
            const currency = advertisers.map(advertiser => advertiser?.writeOff.currency)[0]?.toUpperCase();
            const min = Math.min.apply(Math, writeOffs);
            const max = Math.max.apply(Math, writeOffs);
    
            if (min === max) {
              return this.formatCurrency(min, currency);
            }
            return `${ this.formatCurrency(this.toFixed(min, 2), currency) } –
             ${ this.formatCurrency(this.toFixed(max, 2), currency) }`;
          }
        }
      },

      formatCommission (individualRate, commonRate, advertisers) {
        const rate = individualRate ? individualRate?.value : commonRate?.value;

        switch (advertisers.length) {
          case 0:
            return "-";
    
          case 1:
            return this.formatCurrency(this.toFixed(advertisers[0].writeOff.value - rate, 2), advertisers[0].writeOff.currency);
    
          default: {
            const writeOffs = advertisers.map(advertiser => parseFloat(advertiser?.writeOff.value));
            const currency = advertisers.map(advertiser => advertiser?.writeOff.currency)[0]?.toUpperCase();
            const min = Math.min.apply(Math, writeOffs);
            const max = Math.max.apply(Math, writeOffs);
      
            if (min === max) {
              return this.formatCurrency(this.toFixed(min - rate, 2), currency);
            }

            return `${ this.formatCurrency(this.toFixed(min - rate, 2), currency) } –
             ${ this.formatCurrency(this.toFixed(max - rate, 2), currency) }`;
          }
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
    ::v-deep {
        .sort-icon {
            line-height: 1.5;
        }

        .tippy {
            & + span {
                .sort-icon {
                    line-height: 2.4;
                }
            }
        }

        ul {
            li {
                &:not(:first-child) {
                    padding-top: 0.2em;
                }

                div {
                    .flag-icon {
                        margin-right: 0.4em;
                    }
                }
            }
        }

        table {
            thead, tbody {
                background-color: white;
            }

            thead {
                tr {
                    &:first-child {
                        th {
                            padding: 0;

                            > div {
                                display: flex;
                                padding: 20px;
                                align-items: center;
                                justify-content: space-between;

                                label {
                                    text-transform: uppercase;
                                }
                            }
                        }
                    }
                }
            }
        }

        .card {
          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
        }
    }

    .icon-btn {
        border: none;
        outline: none;
        cursor: pointer;
        background-color: transparent;
    }
</style>
