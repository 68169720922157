<template>
    <BlockBox
        v-if="callCenters && callCenters.items.length"
        :title="$t('admin.offer.table.callCenters.labels.name')">
        <b-tabs v-model="activeTab">
            <b-tab-item
                :key="i"
                v-for="(tab, i) in tabs"
                :label="$t(`admin.offer.table.callCenters.labels.${tab}`)">
                <TablePlaceholder :data="getCallCentersUTC(callCenters)">
                    <template slot-scope="{ count, items }">
                        <b-table
                            :data="items"
                            :loading="isLoading"
                            :total="count"
                            hoverable
                            striped>
                            <template>
                                <b-table-column
                                    v-slot="{ row }"
                                    :label="$t(`common.entity.callCenters.labels.name`)"
                                    class="statistic-table--column">
                                    <strong class="has-text-weight-semibold">
                                        {{ row.name }}
                                    </strong>
                                </b-table-column>
                                <b-table-column
                                    :key="index"
                                    v-for="(day, index) in daysOfWeek"
                                    v-slot="{ row }"
                                    :label="$t(`common.entity.callCenters.daysOfWeek.full.${day}`)"
                                    class="statistic-table--column">
                                    <LTooltip
                                        :active="!!dayEvents(row.timeTables[day]).tooltipText"
                                        :label="dayEvents(row.timeTables[day]).tooltipText"
                                        position="is-top"
                                        type="is-dark">
                                        <span
                                            :class="dayEvents(row.timeTables[day]).class"
                                            style="white-space: pre">
                                            {{ dayEvents(row.timeTables[day]).label || dayEvents(row.timeTables[day]) }}
                                        </span>
                                    </LTooltip>
                                </b-table-column>
                            </template>
                        </b-table>
                    </template>
                </TablePlaceholder>
            </b-tab-item>
        </b-tabs>
    </BlockBox>
</template>

<script>
  import { CallCenters } from "@core/mixins";
  import BlockBox from "@/components/Common/BlockBox";
  import TablePlaceholder from "@/components/Common/Table/TablePlaceholder";
  
  export default {
    name: "CallCentersTable",
    mixins: [CallCenters],
    components: { TablePlaceholder, BlockBox },
    props: {
      callCenters: {
        type: Object,
        default: null
      },
      isLoading: {
        type: Boolean,
        default: null
      }
    },
    data () {
      return {
        activeTab: 0
      };
    },
    computed: {
      daysOfWeek () {
        return ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
      },

      userTimeZone () {
        return this.$store.getters.timezone;
      },

      tabs () {
        return ["userTimeZone", "callCentersTimeZone"];
      }

    },

    watch: {
      activeTab (value) {
        this.$emit("changeTab", value);
      }
    }
  };
</script>

<style lang="scss" scoped>
    .time {
        min-width: 72px;
    }

    ::v-deep{
        .table-wrapper {
            overflow-x: auto;
        }
    }
</style>
