<template>
    <TablePlaceholder :data="data">
        <template slot-scope="{ count, items }">
            <FTable
                :current-page="page"
                :data="items"
                :is-loading="isLoading"
                :per-page.sync="perPage"
                :total="count"
                @update:currentPage="updateCurrentPage">
                <el-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.offer.table.offerWebmasters.labels.webmaster`)">
                    <div class="is-flex is-align-items-center">
                        <UserOutput :user="row.webmaster"></UserOutput>
                        <LTooltip
                            :label="$t(`admin.users.webmasters.table.values.detailsModalLabel`)"
                            class="ml-auto"
                            position="is-bottom"
                            type="is-info">
                            <b-tag
                                class="is-clickable"
                                type="is-info is-light"
                                disabled
                                @click.native="webmastersTableDetailsModalOpen(row.webmaster)">
                                <b-icon
                                    icon="signal"
                                    size="is-small"></b-icon>
                            </b-tag>
                        </LTooltip>
                    </div>
                </el-table-column>

                <el-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.offer.table.offerWebmasters.labels.manager`)">
                    <CompactList
                        :items="manager(row)">
                        <template #list="{ item }">
                            <UserOutput :user="item"></UserOutput>
                        </template>
                        <template #tooltipItem="{ item }">
                            <UserOutput :user="item"></UserOutput>
                        </template>
                        <template #singleItem="{ item }">
                            <UserOutput :user="item"></UserOutput>
                        </template>
                    </CompactList>
                </el-table-column>

                <el-table-column
                    v-slot="{ row }"
                    :label="$t(`common.entity.withdrawType.withdrawType`)">
                    {{ withdrawType(row) }}
                </el-table-column>
                <el-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.offer.table.offerWebmasters.labels.moneyRewards`)">
                    {{ moneyReward(row) }}
                </el-table-column>

                <el-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.offer.table.offerWebmasters.labels.advertiserWriteOff`)">
                    {{ advertiserWriteOffRangeOutput(row) }}
                </el-table-column>
                <el-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.offer.table.offerWebmasters.labels.commission`)">
                    {{ calcCommission(row) }}
                </el-table-column>
            </FTable>
            <WebmastersTableDetailsModal
                :is-active.sync="detailsModal"
                :webmaster="detailsModalData">
            </WebmastersTableDetailsModal>
        </template>
        <template #empty>
            <div class="hero has-text-centered">
                <div class="hero-body">
                    <h2 class="is-size-5 is-bold has-text-weight-medium has-text-grey">
                        <slot name="empty">
                            {{ $t("admin.offer.table.offerWebmasters.labels.empty") }}
                        </slot>
                    </h2>
                </div>
            </div>
        </template>
    </TablePlaceholder>
</template>

<script>
  import WebmastersTableDetailsModal from "@/components/Admin/Users/WebmastersTab/WebmastersTableDetailsModal";
  import TablePlaceholder from "@/components/Common/Table/TablePlaceholder";
  import UserOutput from "@/components/Common/UserOutput";
  import { formatCurrency, formatEmptyString, toFixed } from "@core/filters";
  import { getMinMax } from "@core/helpers/getMinMax";
  import { GET_OFFER_WEBMASTERS } from "@core/store/action-constants";
  import { SET_EMPTY, SET_OFFER_WEBMASTERS_ID, UPDATE_PAGINATION } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapActions, mapMutations, mapState } from "vuex";
  import FTable from "../FTable";
  import CompactList from "@/components/Common/CompactList";

  export default {
    name: "OfferWebmastersTable",
    
    components: {
      TablePlaceholder,
      UserOutput,
      WebmastersTableDetailsModal,
      FTable,
      CompactList
    },
    props: {
      offer: {
        type: Object,
        default: null
      }
    },
    
    data () {
      return {
        detailsModal: false,
        detailsModalData: null
      };
    },

    computed: {
      ...mapState("admin/offer/webmasters", [
        "data",
        "offerId"
      ]),

      ...mapFields("admin/offer/webmasters", {
        fields: [
          "page",
          "perPage"
        ],
        base: "pagination",
        action: UPDATE_PAGINATION
      }),
  
      offerId () {
        return this.offer?.id;
      },

      isPaginated () {
        return this.data && !isNaN(this.data.count) ? this.data.count > this.perPage : false;
      },

      isLoading () {
        return this.$wait(`admin/offer/webmasters/${ GET_OFFER_WEBMASTERS }`);
      }
    },
    methods: {
      ...mapActions("admin/offer/webmasters", [
        GET_OFFER_WEBMASTERS
      ]),

      ...mapMutations("admin/offer/webmasters", [
        SET_EMPTY
      ]),

      manager (row) {
        return row.webmaster?.admins || [];
      },

      getAdvertiserWriteOffRange (writeOffs = []) {
        if (writeOffs?.length) {
          const { min, max } = getMinMax(writeOffs);
          return [min, max];
        }
      },

      advertiserWriteOffRangeOutput ({ advertiserWriteOffs }) {
        const advertiserWriteOffRange = this.getAdvertiserWriteOffRange(advertiserWriteOffs);

        if (advertiserWriteOffRange) {
          const [minWriteOff, maxWriteOff] = advertiserWriteOffRange
            .map(writeOff => this.formatCurrency(toFixed(writeOff.value, 2), writeOff.currency));

          return minWriteOff === maxWriteOff ? minWriteOff : `${ minWriteOff } - ${ maxWriteOff }`;
        }

        return this.formatEmptyString();
      },

      calcCommission ({ advertiserWriteOffs, individualRate }) {
        const { offer } = this;
        const advertiserWriteOffRange = this.getAdvertiserWriteOffRange(advertiserWriteOffs);

        if (advertiserWriteOffRange) {
          const reward = individualRate?.moneyReward?.value ?? offer?.finance?.webmasterReward?.value;
          const [minWriteOff, maxWriteOff] = advertiserWriteOffRange
            .map(writeOff => this.formatCurrency(toFixed(writeOff.value - reward, 2), writeOff.currency));

          return minWriteOff === maxWriteOff ? minWriteOff : `${ minWriteOff } - ${ maxWriteOff }`;
        }

        return this.formatEmptyString();
      },

      withdrawType (row) {
        const withdrawType = (row.individualRate?.withdrawType || this.offer?.finance?.withdrawType)?.toLowerCase();
        return withdrawType ? this.$t(`common.entity.withdrawType.${ withdrawType }`) : this.formatEmptyString();
      },

      moneyReward (row) {
        const reward = row.individualRate?.moneyReward || this.offer?.finance?.webmasterReward;
        return this.formatCurrency(reward?.value, reward?.currency);
      },

      updateCurrentPage (page) {
        this.page = page;
        this.GET_OFFER_WEBMASTERS(this.offerId);
      },

      webmastersTableDetailsModalOpen (value) {
        this.detailsModal = true;
        this.detailsModalData = value;
      },

      formatEmptyString,
      formatCurrency
    },

    watch: {
      offer: {
        immediate: true,
        handler (value) {
          if (value) {
            this.GET_OFFER_WEBMASTERS(this.offerId);
          }
        }
      }
    },

    destroyed () {
      this.SET_EMPTY();
    }
  };
</script>

<style lang="scss" scoped></style>
