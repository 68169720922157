<template>
    <div>
        <Card v-if="offer">
            <div class="asideTable-wrapper">
                <b-table
                    :data="data"
                    :loading="!offer"
                    :count="data.length"
                    hide-pagination
                    class="asideTable">
                    <b-table-column
                        v-slot="{ row }"
                        :label="$t(`webmaster.offer.aside.webmasterReward`)"
                        centered>
                        {{ webmasterRewardWithCurrency(row) }}
                    </b-table-column>
                    <b-table-column
                        v-slot="{ row }"
                        :label="$t(`webmaster.offer.aside.approve`)"
                        centered>
                        {{ formatEmptyString(row.approve) }}
                    </b-table-column>
                    <b-table-column
                        v-slot="{ row }"
                        :label="$t(`webmaster.offer.aside.withdrawTypes.title`)"
                        centered>
                        {{ formatEmptyString(row.withdrawType) }}
                    </b-table-column>
                </b-table>
            </div>
        </Card>
    </div>
</template>

<script>
  import { formatPercent, toFixed, formatCurrency, formatEmptyString } from "@core/filters";
  import Card from "@/components/Common/Card";
  
  export default {
    name: "OfferWebmasters",
    components: { Card },
    props: {
      offer: {
        type: Object,
        default: null
      },
      statistics: {
        type: Object,
        default: null
      }
    },
    computed: {
      data () {
        const data = {};

        if (this.offer) {
          data.webmasterReward = this.offer.webmasterReward;
          data.withdrawType = this.$t(`webmaster.offer.aside.withdrawTypes.${ this.offer.withdrawType.toLowerCase() }`);
        }

        if (this.statistics) {
          data.approve = `${ this.toFixed(this.formatPercent(this.statistics.aggregates.average.conversionApprove), 2) } %`;
        }

        return [data];
      }
    },

    methods: {
      toFixed,

      formatPercent,

      formatCurrency,

      formatEmptyString,

      webmasterRewardWithCurrency (row) {
        return this.formatCurrency(row.webmasterReward?.value, row.webmasterReward?.currency);
      }
    }
  };
</script>

<style scoped>

</style>
