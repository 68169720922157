<template>
    <div>
        <IndividualRateFilters
            :filters.sync="filters"
            :is-loading="isLoading"
            :webmaster="webmaster"
            @clear="clearFilters"
            @update="update"></IndividualRateFilters>
        <IndividualRateTable
            :data="individualRates"
            :is-loading="isLoading">
        </IndividualRateTable>
    </div>
</template>

<script>
  import { FilteredComponent } from "@core/mixins";
  import { mapActions, mapState } from "vuex";
  import IndividualRateFilters
    from "@/components/Admin/Users/WebmastersTab/WebmastersTableDetailsModal/WebmastersTableDetailsModalIndividualRate/IndividualRateFilters";
  import IndividualRateTable
    from "@/components/Admin/Users/WebmastersTab/WebmastersTableDetailsModal/WebmastersTableDetailsModalIndividualRate/IndividualRateTable";
  
  export default {
    name: "WebmastersTableDetailsModalIndividualRate",
    mixins: [FilteredComponent],
    components: {
      IndividualRateTable,
      IndividualRateFilters
    },
    props: {
      webmaster: {
        type: Object,
        default: null
      },
      isActive: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        filters: {
          offerId: null,
          countries: null
        },
        isLoading: false
      };
    },
    computed: {
      ...mapState({
        individualRates: state => state.admin.offers.webmasterIndividualRates
      })
    },
    methods: {
      ...mapActions("admin/offers", [
        "GET_INDIVIDUAL_RATES"
      ]),

      async update () {
        if (this.isActive) {
          this.isLoading = true;
          await this.GET_INDIVIDUAL_RATES({
            filters: { ...this.filters },
            webmasterId: this.webmaster ? this.webmaster.id : null
          });
          this.isLoading = false;
        }
      }
    },
    watch: {
      isActive (value) {
        if (value) this.update();
      }
    }
  };
</script>

<style scoped>

</style>
