import { CREATE_BASE_LINK } from "@core/store/action-constants";
import { mapGetters } from "vuex";

// @vue/components
export const FlowsTable = {
    computed: {
        ...mapGetters("webmaster/flows", {
            createBaseLink: CREATE_BASE_LINK
        })
    },
    
    methods: {
        createLink (params) {
            try {
                return this.createBaseLink(params);
            } catch (e) {
                return this.$t("webmaster.flows.table.values.noLink");
            }
        },
        
        shortId (id) {
            const shortId = id.toString().split("-");
            
            return shortId[shortId.length - 1];
        },
        
        editFlow (flowId) {
            this.$router.push({
                name: "webmaster:flows:edit",
                query: {
                    flowId
                }
            }).catch(_ => {});
        }
    }
};
