<template>
    <el-dialog
        :visible="isModalActive"
        class="flex-wrap"
        @close="closeModal">
        <template #title>
            <span class="text-xl font-bold text-blue-text">{{ $t("webmaster.offer.modals.offerLinkModal.title") }}</span>
        </template>
        <WebmasterEditLinkTemplate
            :url="url"
            :fields="fields"
            key-no-link="webmaster.offer.modals.noLink"
            @update:flowFields="UPDATE_OFFER_FIELDS">
        </WebmasterEditLinkTemplate>
        <template #footer>
            <slot name="footer"></slot>
        </template>
    </el-dialog>
</template>

<script>
  import { Modal } from "@core/mixins";
  import { GENERATE_OFFER_URL, UPDATE_OFFER_FIELDS } from "@core/store/action-constants";
  import { SET_EMPTY, UPDATE_MODAL_ACTIVE } from "@core/store/mutation-constants";
  import { mapActions, mapMutations, mapState } from "vuex";
  import { FlowsTable } from "@core/mixins/FlowsMixin";
  import WebmasterEditLinkTemplate from "@/components/Common/Webmaster/WebmasterEditLinkTemplate";
  
  export default {
    name: "OfferEditLinkModal",
    mixins: [FlowsTable, Modal],      
    components: {
      WebmasterEditLinkTemplate
    },
      
    computed: {
      ...mapState("webmaster/offer/offerEditLinkModal", {
        modalActive: ({ isModalActive }) => isModalActive,
        fields: ({ fields }) => fields,
        url: ({ url }) => url?.toString()
      }),
      
      isModalActive: {
        get () {
          return this.modalActive;
        },
        
        set (value) {
          this.UPDATE_MODAL_ACTIVE(value);
        }
      }
    },
    
    methods: {
      ...mapActions("webmaster/offer/offerEditLinkModal", {
        UPDATE_OFFER_FIELDS,
        GENERATE_OFFER_URL
      }),

      ...mapActions("webmaster/offer/offerEditLinkModal", {
        UPDATE_MODAL_ACTIVE
      }),
      
      ...mapMutations("webmaster/offer/offerEditLinkModal", {
        SET_EMPTY
      })
    }
  };
</script>

<style scoped lang="scss">
    ::v-deep {
        .el-dialog {
            background-color: #FBFCFE;
            min-width: calc(250px + 15vw);
        }
        
        .el-dialog__header {
            padding: 32px 32px 0;
        }
    
        .el-dialog__body {
            padding: 0 32px 32px;
        }

        .el-dialog__footer {
            padding-left: 32px;
            padding-right: 32px;
        }

        .input-url {
            .el-input--suffix .el-input__inner {
                padding-right: 12rem;
                text-overflow: ellipsis;
            }
        }

        [class*=" el-icon-"], [class^=el-icon-] {
            color: #FE2615;
        }
    }
</style>